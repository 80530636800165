import axios from './index'

class myUrl {

    static myUrl(params) {
        return axios.post(`NurseHomeControl.action`, params)
            // return axios.post(`http://h5.yihu365.com/NurseHomeControl.action`, params)
    }
    static getMy(params) {
        return axios.get(`NurseHomeControl.action`, {params:params})
            // return axios.post(`http://h5.yihu365.com/NurseHomeControl.action`, params)
    }
    
}

export default myUrl;

<template>
    <div class="wrapper">
<!--       邀请好友页面-->
      <img class="bg" src="https://static.yihu365.cn/img/h5Img/yp_bg3.png" alt="">
      <div class="content">
        <div class="notice">
          <van-notice-bar left-icon="volume-o" :scrollable="false"  background="#F3221E" color="#ffffff">
            <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="3000"
                :touchable="false"
                :show-indicators="false"

            >
              <van-swipe-item v-for="(item,index) in tzList">{{item}}</van-swipe-item>

            </van-swipe>
          </van-notice-bar>
        </div>
         <div class="btn" @click="setShare">
           <img src="https://static.yihu365.cn/img/h5Img/yq_btn.png" alt="">
         </div>
        <div class="main_box">
            <img class="tit" src="https://static.yihu365.cn/img/h5Img/yp_tit2.png" alt="">
            <div class="details">
                <div class="list" v-for="(item,index) in list.slice(0,3)">
                    <div class="tx">
                      <img class="tu1" :src="item.headImg" alt="">
                      <img class="tu2"  :src="txList[index]" alt="">
                    </div>
                   <p>{{ item.userName }}</p>
                    <div class="price">
                        {{item.award}}
                    </div>
                </div>
            </div>
        </div>
        <div class="main_box">
          <img class="tit2" src="https://static.yihu365.cn/img/h5Img/yp_tit3.png" alt="">
          <div class="details sy_box" >
                <div class="top_tit" >
                   <span>用户</span>
                   <span>赚取收益</span>
                   <span>奖励</span>
                </div>
                <div class="sy_list" :class="{ 'height350': !flag, 'height450': flag}">
                  <div class="lb" v-for="(item,index) in list.slice(0,20)">
                    <img class="t1" v-if="index < 3" :src="iconList[index]" alt="">
                    <div class="xh" v-else>{{index+1}}</div>
                    <img class="t2" :src="item.headImg" alt="">
                    <span class="yh">{{ item.userName }}</span>
                    <span class="sy">{{item.income}}</span>
                    <span class="jl">{{item.award}}</span>
                  </div>
                </div>
               <div class="sy_btn" @click="checkoutFlag">
                 <div class="jtou" :class="{ 'arrowTransform': !flag, 'arrowTransformReturn': flag}" ><van-icon name="arrow-up" size="16"/></div>
                       <div>数据更新：7日前</div>
               </div>
          </div>
          <div class="main_box">
            <img class="tit2" src="https://static.yihu365.cn/img/h5Img/yp_tit4.png" alt="">
            <div class="details txt">
              <p>1.奖励金为现金红包，在次月工作人员统计发放</p>
              <p>2.邀请新用户登录医护到家APP，邀请人可获得相应奖励金</p>
              <p>3.被邀请人次周留存率需大于60%，如留存率小于60%只计算有效用户</p>
              <p>4.推广冲榜大奖为本月内赚取收益金额，以产生受益最多排名排名前20名，获得排名活动奖励，奖励会在次月第一周内统一联系发放</p>
              <p>5.新用户接受邀请后可获得价值190元券包，快去发起邀请吧~</p>
            </div>
        </div>
          <div class="btn2" @click="setShare">
             <img src="https://static.yihu365.cn/img/h5Img/yq_btn.png" alt="">
          </div>
      </div>
    </div>
      <van-overlay
          :show="show"
          @click="show = false"
          z-index="100"
      >
            <div class="topBox">
              <div class="title">即将打开新页面...</div>
              <img src="https://static.yihu365.cn/img/h5Img/rightTop.jpg" alt="">
            </div>
      </van-overlay>
    </div>
</template>

<script>
import myApi from "@axios/my";
import homeApi from '@axios/home';
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import { Toast } from 'vant';
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed} from "vue"
export default {
  name: "invitation",
  setup() {
    let router = useRouter()
    const route = useRoute()
    const store = useStore()
    setWxShareDate(`职业护士分享赚佣金，用户注册享特惠。居家护理省时又安心`,`https://static.yihu365.cn/img/wx-uni/logo_kts.png`,`https://mi.yihu365.cn/invitation`)//分享

    const flag = ref(false)
    const checkoutFlag = () => {
      flag.value = !flag.value
      console.log(flag.value)
    }
    const txList = ref([
      'https://static.yihu365.cn/img/h5Img/yp_icon1.png',
        'https://static.yihu365.cn/img/h5Img/yp_icon2.png',
        'https://static.yihu365.cn/img/h5Img/yp_icon3.png'
    ])
    const iconList = ref([
      'https://static.yihu365.cn/img/h5Img/yqmc_icon3.png',
        'https://static.yihu365.cn/img/h5Img/yqmc_icon1.png',
        'https://static.yihu365.cn/img/h5Img/yqmc_icon2.png'

    ])
    const tzList = ref(["153****5805邀请好友，获得20元奖励金","166****5615邀请好友，获得18元奖励金","131****9012邀请好友，获得19元奖励金","185****6522邀请好友，获得8元奖励金","151****7085邀请好友，获得24元奖励金","131****0753邀请好友，获得40元奖励金","171***1444邀请好友，获得36元奖励金","185****0550邀请好友，获得12元奖励金","131****7012邀请好友，获得24元奖励金","156****5073邀请好友，获得17元奖励金","155****14930邀请好友，获得6元奖励金","176****8762邀请好友，获得22元奖励金","166****8470邀请好友，获得13元奖励金","171****2444邀请好友，获得7元奖励金","150****1331邀请好友，获得16元奖励金","178****1567邀请好友，获得8元奖励金","130****3096邀请好友，获得16元奖励金","186****5598邀请好友，获得33元奖励金","178****8901邀请好友，获得27元奖励金","156****8817邀请好友，获得43元奖励金","130****2680邀请好友，获得22元奖励金","131****1082邀请好友，获得34元奖励金"])
    const list = ref([]);

    const getCommission = () =>{
      console.log('nima')
      homeApi.getCommission().then((res) => {
          console.log('res',res.data.data);
        list.value = res.data.data
      })
    }
    getCommission()

    const phone = ref('')
    const userId = ref('')
    const nickName = ref('')
    const getUserInfo = () => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function: "getUserInfo",
        token: store.state.token,
        userid:store.state.uid,
        login_userId_base: store.state.uid,
        _validate: "1",
        _from: "h5",
        version: store.state.version,
        newversion: store.state.newversion,
      }))
      formData.append('encryption',false)

      homeApi.getList(formData).then((res) => {
        console.log(res);

        if(res.code == '0000'){
          phone.value = res.user.userMobile;
          userId.value = res.user.userId;
          nickName.value = res.user.nickName;
          const url = `http://appyhdj.yihu365.cn/common/register?userid=6961023&sid=90009000110&mobile=18910198722&type=5&activityCode=2`
        }
      })

    }
     if(store.state.token){
       getUserInfo();
     }

    const show = ref(false)

    const setShare = () => {

      router.push({
        name:"registerApp",
        query:{
          userid:userId.value,
          sid:'90009000110',
          mobile:phone.value,
          nickName:nickName.value,
          type:'5',
          activityCode:'2'
        }
      })
         // window.location.href = `http://appyhdj.yihu365.cn/common/register?userid=${userId.value}&sid=90009000110&mobile=${phone.value}&type=5&activityCode=2`

    }


    return {
      flag,
      list,
      tzList,
      iconList,
      txList,
      show,
      checkoutFlag,
      setShare,
      getCommission
    }

  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
   ::v-deep(.van-notice-bar){
     border-radius: 30px;
     height:60px;
   }
    .notice-swipe {
      height: 30px;
      line-height: 30px;
    }
     .wrapper{
       height:3570px;
       background:#FF7631;
       width: 100%;
       position: relative;

       .bg{
         width: 100%;
         position: absolute;
         left: 0;top:0;
       }
       .content{
         box-sizing: border-box;

         padding:0 40px;
         width: 100%;
         position: absolute;
         left:0;top:60px;

         .notice{
           width: 95%;
           margin:0 auto;
           border-radius: 20px;
         }
         .btn{
           animation: rotate 0.8s linear infinite;
           width: 80%;
           margin:1100px auto 0;
           img{
             width: 100%;
           }
         }
         .btn2{
           animation: rotate 0.8s linear infinite;
           width: 80%;
           margin:200px auto 0;
           img{
             width: 100%;
           }
         }

         .sy_box{
            display: block!important;
           .sy_btn{
             box-shadow: 0 -18px 16px #f0f0f0;
             color: #FF6C00;
             font-size: 26px;
             text-align: center;
             padding:12px 0;

           }
         }
         .main_box{
              .sy_list{
                height:240px;
                overflow: scroll;

                 font-size: 26px;
                 .t1{
                   width: 34px;
                   height: 34px;
                 }
                .xh{
                  width: 34px;
                  height: 34px;
                  text-align: center;
                  line-height: 34px;
                  font-size: 30px;
                  color:#333333;
                }
                  .t2{
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin:0 20px;
                  }
                .yh{
                   display: block;
                   width:220px;
                   white-space: nowrap;
                }
                .sy{
                  margin-left: 10px;
                   display: block;
                   width:120px;
                   white-space: nowrap;
                   color:#FF6C00;
                   text-align: right;
                }
                .jl{
                   display: block;
                   margin-left: 10px;
                   width:100px;
                   white-space: nowrap;
                   color:#FF6C00;
                  text-align: right;
                }
                .lb{
                  margin:0 auto;
                  width:88%;
                  display: flex;
                  align-items: center;
                  padding:12px 15px;
                  border-bottom: 1px solid #DEDEDE;
                }
              }
             .tit{
               display: block;
               width: 60%;
               margin:40px auto;
             }
             .tit2{
               display: block;
               width: 50%;
               margin:40px auto;
             }
           .txt{

             box-sizing: border-box;
             padding:22px;
             display: block!important;
             p{
               font-size: 26px;
               line-height: 42px;
               color: #333333;
               margin: 0;
             }
           }
           .details{
              box-sizing: border-box;
              font-size: 24px;
              min-height:350px;
              border-radius: 20px;
              background: #FFFFFF;
              display: flex;
              justify-content: space-around;
             .top_tit{
               box-sizing: border-box;
               color:#300C01;
               width: 670px;
               height: 77px;
               background: linear-gradient(1deg, #FFB847 0%, #FFE2B4 100%);
               box-shadow: 0px 3px 9px 0px #DA9885;
               border-radius: 14px 14px 0 0;
               font-size: 30px;
               line-height: 80px;
               display: flex;
               justify-content: space-around;
               span:nth-of-type(1){
                 margin-left: 95px;
                 flex-grow: 1;
               }
               span:nth-of-type(3){
                 margin-left: 40px;
                 margin-right: 40px;
               }
             }
             .list:nth-of-type(1){
               order:1
             }
             .list:nth-of-type(2){
               order:0
             }
             .list:nth-of-type(3){
               order:2
             }
             .list{
               //width:240px;
               height:350px;
               //border:1px solid red;
               display: flex;
               flex-direction: column;
               align-items: center;

                .tx{
                  position: relative;
                  width:130px;
                  height:150px;
                  //border:1px solid red;
                  margin-top: 30px;
                  background-position: left top;
                  background-size: 100% 100%;
                   .tu1{
                     display: block;
                     margin: 45px auto 0;
                     width: 94px;
                     height: 94px;
                     border-radius: 50%;
                     position: absolute;
                     left:18px;top:0;
                     z-index: 1;
                   }
                  .tu2{
                    width: 130px;
                    height: 158px;
                    position: absolute;
                    left:0;top:0;
                    z-index: 2;
                  }
                }

               p{
                 margin:20px 0;
               }
                 .price{
                   color: #731705;
                   box-sizing: border-box;
                    width:144px;
                    height:50px;
                    line-height: 56px;
                    text-align: right;
                   padding-right: 14px;
                    background-image: url("https://static.yihu365.cn/img/h5Img/yp_bgicon.png");
                    background-position: left top;
                    background-size: 100% 100%;
                    //border:1px solid red;
                 }
             }
           }
         }
       }
     }

   .list:nth-of-type(1) .tx{
      .tu1{
        top:-20px!important;
        width:117px!important;
        height:117px!important;
      }
     .tu2{
       top:-22px!important;
       width: 150px!important;
       height: 180px!important;
     }
   }




   @keyframes rotate {
     0% {
       transform: translateY(0) ;
     }
     25% {
       transform: translateY(10px);
     }
     50% {
       transform: translateY(20px);
     }
     75% {
       transform: translateY(10px) ;
     }
     100% {
       transform: translateY(0) ;
     }
   }
   .height450{
     height:500px!important;
     transition: 0.3s;
   }
   .height350{
     height:250px!important;
     transition: 0.3s;
   }
   .arrowTransform{
     transition: 0.2s;
     transform-origin: center;
     transform: rotateZ(180deg);
   }
   .arrowTransformReturn{
     transition: 0.2s;
     transform-origin: center;
     transform: rotateZ(0deg);
   }


   .topBox{
      text-align: right;
     .title{
       margin-top:30px;
       text-align: center;
       color:#FFFFFF;
     }
      img{

      }
   }
</style>

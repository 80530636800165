import { render } from "./invitation.vue?vue&type=template&id=22c415f0&scoped=true"
import script from "./invitation.vue?vue&type=script&lang=js"
export * from "./invitation.vue?vue&type=script&lang=js"

import "./invitation.vue?vue&type=style&index=0&id=22c415f0&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-22c415f0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "22c415f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('22c415f0', script)) {
    api.reload('22c415f0', script)
  }
  
  module.hot.accept("./invitation.vue?vue&type=template&id=22c415f0&scoped=true", () => {
    api.rerender('22c415f0', render)
  })

}

script.__file = "src/views/static/invitation/invitation.vue"

export default script